import React from "react"
import Layout from "../components/layout"
import SEO from "../components/Seo"

const ContactPage = () => {
  return (
    <>
      <Layout>
      <SEO
      title= {'Tentang Kami'}
      description= {'Untuk mengetahui lebih dalam tentang pasaran togel, MAJAHNEWS akan membantu anda menemukan informasi terbaik tentang pasaran togel di Indonesia.'}/>

        <h1>Tentang Kami MAJAHNEWS</h1>
        <p>
        Togel merupakan salah satu permainan taruhan berbasis tebak angka yang menyediakan pilihan permainan yang lengkap, hadiah yang besar, dan juga modal yang sangat kecil. Tak heran, permainan ini begitu digemari oleh semua kalangan. Apalagi, ada setidaknya 10 pasaran togel paling terkenal di Indonesia yang menawarkan begitu banyak keuntungan.
        </p>
        <p>
        Bagi yang ingin terjun dalam dunia togel online, tentu langkah pertama yang harus kalian pilih yaitu pasaran yang tepat. Hal ini karena masing-masing pasaran memiliki aturan, hadiah, dan juga sistem yang berbeda satu sama lain. Untuk mengetahui lebih dalam tentang pasaran togel, <a href="https://majahnews.com">MAJAHNEWS</a> akan membantu anda menemukan informasi terbaik tentang pasaran togel di Indonesia.
        </p>
      </Layout>
    </>
  )
}

export default ContactPage
